<template>
    <page v-if="cidade">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'cidades'}">Cidades</v-breadcrumbs-item>
        </v-breadcrumbs>
        <div>
            <span class="title font-weight-regular d-inline-flex" style="line-height: normal; align-items: center">
                {{cidade.nome}} - {{cidade.estado.sigla}}
            </span>
        </div>

<!--        <v-img :src="getMapImageUrl(cidade.nome + '/' + cidade.estado.sigla)"
               width="100%"  class="fill-height" style="border: 1px solid #519657" >
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="orange"></v-progress-circular>
                </v-row>
            </template>
        </v-img>-->

        <v-card outlined class="mt-6 mb-6" v-if="agencias">
<!--            <v-card-title class="pb-0">
                Agências
                <span v-if="agencias.length > 0" class="ml-2 subtitle-2 font-weight-regular">{{agencias.length}} resultados encontrados</span>
            </v-card-title>-->
            <v-card-text class="pa-0">
                <v-tabs color="primary" >
                    <v-tab>AGÊNCIAS <v-chip v-if="agenciasTotalCount > 0" x-small label color="orange" text-color="white" class="px-1 ml-2" >{{agenciasTotalCount}}</v-chip> </v-tab>
                    <v-tab>FISCALIZAÇÕES <v-chip v-if="fiscalizacoesTotalCount > 0" x-small label color="orange" text-color="white" class="px-1 ml-2" >{{fiscalizacoesTotalCount}}</v-chip> </v-tab>
                    <v-tab>PROJETOS <v-chip v-if="projetosTotalCount > 0" x-small label color="orange" text-color="white" class="px-1 ml-2" >{{projetosTotalCount}}</v-chip> </v-tab>
                    <v-tab-item :transition="false"  :reverse-transition="false">
                        <div class="text-center py-2" v-if="agencias">
                            <v-pagination v-model="agenciasPage" total-visible="8"
                                          :length="Math.ceil(agenciasTotalCount / 10)"
                                          @input="agenciasPageChanged"></v-pagination>
                        </div>

                        <v-list class="mt-2 py-0" two-line v-if="agencias && agencias.length > 0">
                            <template v-for="(agencia, index) in agencias">
                                <v-list-item  :key="index" :to="{ name: 'agencia_view', params: { id: parseInt(agencia.id) }}" >
                                    <v-list-item-content>
                                        <v-list-item-title>{{agencia.codigo + ' - ' + agencia.nome}}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            <template v-if="agencia.totalFiscalizacoes > 0">
                                                <v-chip x-small label color="teal lighten-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                                    <span>{{agencia.totalFiscalizacoes}} Fiscalizações</span>
                                                </v-chip>
                                                <v-chip v-if="agencia.totalFiscalizacoesPendentes > 0" x-small label color="orange" text-color="white" class="mr-1 font-weight-bold px-2">
                                                    <span>{{agencia.totalFiscalizacoesPendentes}} pendentes</span>
                                                </v-chip>
                                            </template>
                                            <v-chip v-else x-small label color="grey lighten-2" text-color="black" class="mr-1 font-weight-regular px-2">
                                                <span>Nenhuma fiscalização</span>
                                            </v-chip>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-list-item-action-text>
                                            <v-chip v-if="agencia.fechadaEm" x-small label color="red" dark class="mr-1 font-weight-bold px-2">
                                                <span>FECHADA</span>
                                            </v-chip>
                                            <v-chip v-if="agencia.centralNf" x-small label color="blue" text-color="white" class="mr-1 font-weight-bold px-2">
                                                <span>Centralizadora</span>
                                            </v-chip>
                                        </v-list-item-action-text>
                                    </v-list-item-action>

                                </v-list-item>
                                <v-divider ></v-divider>
                            </template>
                        </v-list>
                        <div v-else-if="loading === false && (agencias === null || agencias.length === 0)" class="text-center pa-6">
                            <h1 class="display-3 mb-4">Ops!</h1>
                            <span class="headline font-weight-light">Não existe nenhuma agência para essa cidade!</span>
                        </div>

                        <div class="text-center py-2" v-if="agencias">
                            <v-pagination v-model="agenciasPage" total-visible="8"
                                          :length="Math.ceil(agenciasTotalCount / 10)"
                                          @input="agenciasPageChanged"></v-pagination>
                        </div>
                    </v-tab-item>
                    <v-tab-item :transition="false"  :reverse-transition="false">
                        <span class="ml-2 py-2 d-block caption">* Lista pela cidade da área e da agência</span>
                        <div class="text-center py-2" v-if="fiscalizacoes">
                            <v-pagination v-model="fiscalizacoesPage" total-visible="8"
                                          :length="Math.ceil(fiscalizacoesTotalCount / 10)"
                                          @input="fiscalizacoesPageChanged"></v-pagination>
                        </div>

                        <v-list class="mt-2 py-0" two-line v-if="fiscalizacoes && fiscalizacoes.length > 0">
                            <template v-for="(fiscalizacao, index) in fiscalizacoes">
                                <v-list-item :dense="$vuetify.breakpoint.xsOnly" :key="fiscalizacao.id" :class="getFinalizadoClass(fiscalizacao)"  :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}" >
                                    <v-list-item-avatar>
                                        <v-icon class="light-green lighten-3" v-text="getItemIcon(fiscalizacao.tipo)"></v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-if="!$vuetify.breakpoint.xsOnly" v-html="fiscalizacao.financiamento.mutuario"></v-list-item-title>
                                        <v-list-item-title v-else class="body-2" v-html="fiscalizacao.financiamento.mutuario"></v-list-item-title>
                                        <v-list-item-subtitle v-if="!$vuetify.breakpoint.xsOnly" v-html="'N. Solicitação: ' + fiscalizacao.numeroSolicitacao + '&emsp;Operação: ' + fiscalizacao.financiamento.operacao+'-'+fiscalizacao.financiamento.operacaoDigito" >
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else class="caption" style="line-height: 10px" v-html="'N. Sol.: ' + fiscalizacao.numeroSolicitacao + '&emsp;Op.: ' + fiscalizacao.financiamento.operacao+'-'+fiscalizacao.financiamento.operacaoDigito" >
                                        </v-list-item-subtitle>
                                        <div v-if="false">
                                            <v-tooltip bottom v-if="fiscalizacao.emailEnviadoAt">
                                                <template v-slot:activator="{ on }">
                                                    <v-chip v-on="on" x-small label color="blue" text-color="white" class="mr-1 font-weight-bold px-2">
                                                        EMAIL ENVIADO
                                                    </v-chip>
                                                </template>
                                                <span>{{fiscalizacao.emailEnviadoAt | moment('DD MMM YYYY HH:mm [hrs]')}}</span>
                                            </v-tooltip>

                                            <v-chip v-if="fiscalizacao.fotos.length > 0" x-small label color="orange" text-color="white" class="mr-1 font-weight-bold px-2">
                                                <span>{{fiscalizacao.fotos.length}} {{fiscalizacao.fotos.length > 1 ? "FOTOS" : "FOTO"}}</span>
                                            </v-chip>

                                            <v-chip v-if="fiscalizacao.arquivos.length > 0" x-small label color="teal lighten-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                                <span>{{fiscalizacao.arquivos.length}} {{fiscalizacao.arquivos.length > 1 ? "ANEXOS" : "ANEXO"}}</span>
                                            </v-chip>

                                            <v-chip v-if="fiscalizacao.contatos.length > 0" x-small label color="blue-grey darken-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                                <span>{{fiscalizacao.contatos.length}} {{fiscalizacao.contatos.length > 1 ? "CONTATOS" : "CONTATO"}}</span>
                                            </v-chip>

                                        </div>
                                    </v-list-item-content>

                                    <v-list-item-action v-if="!$vuetify.breakpoint.xsOnly">
                                        <v-list-item-action-text >Data Limite: <b>{{new Date(fiscalizacao.dataLimite) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                                        <v-list-item-action-text v-if="fiscalizacao.dataFiscalizacao">Data Fiscalização: <b>{{new Date(fiscalizacao.dataFiscalizacao) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                                    </v-list-item-action>
                                    <div v-if="fiscalizacao.dataCancelamento" class="d-flex justify-center align-center"
                                         style="position: absolute; width: 100%; height: 100%; left: 0; top: 0; background: repeating-linear-gradient(45deg,transparent,transparent 30px,rgba(255,0,0,0.1) 1px, rgba(255,0,0,0.1) 60px
  )">
                                        <span class="text-h4 font-weight-black">CANCELADO</span>
                                    </div>
                                </v-list-item>

                                <v-divider ></v-divider>
                            </template>
                        </v-list>
                        <div v-else-if="loading === false && (fiscalizacoes === null || fiscalizacoes.length === 0)" class="text-center pa-6">
                            <h1 class="display-3 mb-4">Ops!</h1>
                            <span class="headline font-weight-light">Nenhuma fiscalização executada para essa agência!</span>
                        </div>

                        <div class="text-center py-2" v-if="fiscalizacoes">
                            <v-pagination v-model="fiscalizacoesPage" total-visible="8"
                                          :length="Math.ceil(fiscalizacoesTotalCount / 10)"
                                          @input="fiscalizacoesPageChanged"></v-pagination>
                        </div>
                    </v-tab-item>
                    <v-tab-item :transition="false"  :reverse-transition="false">
                        <div class="text-center py-2" v-if="projetos">
                            <v-pagination v-model="projetosPage" total-visible="8"
                                          :length="Math.ceil(projetosTotalCount / 10)"
                                          @input="projetosPageChanged"></v-pagination>
                        </div>

                        <v-list class="projetos py-0" two-line v-if="projetos && projetos.length > 0">
                            <template v-for="(projeto, index) in projetos">
                                <v-list-item :key="projeto.id" :to="{ name: 'projeto_view', params: { id: parseInt(projeto.id) }}" >
                                    <v-list-item-content>
                                        <v-list-item-title >{{projeto.cliente}}</v-list-item-title>
                                        <div>
                                            <v-chip x-small label color="orange" text-color="white" class="mr-1 font-weight-bold px-2">
                                                <span>{{projeto.tipo.nome}}</span>
                                            </v-chip>
                                        </div>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-list-item-action-text >Data Solicitação: <b>{{new Date(projeto.dataSolicitacao) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                                        <v-list-item-action-text v-if="projeto.dataEnvio">Data Envio: <b>{{new Date(projeto.dataEnvio) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                                    </v-list-item-action>

                                </v-list-item>

                                <v-divider ></v-divider>
                            </template>
                        </v-list>

                        <div v-else-if="loading === false && (projetos === null || projetos.length === 0)" class="text-center pa-6">
                            <h1 class="display-3 mb-4">Ops!</h1>
                            <span class="headline font-weight-light">Nenhum projeto executado para essa agência!</span>
                        </div>

                        <div class="text-center py-2" v-if="projetos">
                            <v-pagination v-model="projetosPage" total-visible="8"
                                          :length="Math.ceil(projetosTotalCount / 10)"
                                          @input="projetosPageChanged"></v-pagination>
                        </div>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
        </v-card>
    </page>
</template>

<script>
    // @ts-ignore
    import page from "../../../components/Page";
    import gql from 'graphql-tag';
    import {getFinalizadoClass, getIconByFiscalizacaoTipo} from "@/assets/FiscaizacaoUtil";

    export default {
        name: "ViewCidade",
        components:{
            page,
        },
        data() {
            return {
                cidade: null,
                loading: false,
                agencias: [],
                agenciasPage: 1,
                agenciasTotalCount: 0,
                fiscalizacoes: [],
                fiscalizacoesPage: 1,
                fiscalizacoesTotalCount: 0,
                projetos: [],
                projetosPage: 1,
                projetosTotalCount: 0,
            }
        },
        mounted() {
            this.getCidade()
        },
        watch:{
            '$route.params.id'(id){
                //this.getAgencia();
                location.reload();
            }
        },
        methods:{
            getItemIcon(tipo){
                return getIconByFiscalizacaoTipo(tipo);
            },
            getFinalizadoClass(fiscalizacao){
                return getFinalizadoClass(fiscalizacao);
            },
            agenciasPageChanged(value){
                this.getAgenciasByCidade(this.cidade.id, value)
            },
            fiscalizacoesPageChanged(value){
                this.getFiscalizacoesByCidade(this.cidade.id, value)
            },
            projetosPageChanged(value){
                this.getProjetosByCidade(this.cidade.id, value)
            },
            getCidade() {
                const id = this.$route.params.id;
                this.$apollo.query({
                    query: gql`query($id: Int!) {
                        cidade(id: $id){
                            id
                            nome
                            codigo
                            estado{
                                id
                                nome
                                sigla
                            }
                        }
                    }`,
                    variables: {id},
                }).then((result) => {
                    this.cidade = result.data.cidade;
                    document.title = this.cidade.nome + ' - ' + this.cidade.estado.sigla + " - JF Assessoria Rural"
                    this.getAgenciasByCidade(this.cidade.id, this.agenciasPage);
                    this.getFiscalizacoesByCidade(this.cidade.id, this.fiscalizacoesPage);
                    this.getProjetosByCidade(this.cidade.id, this.projetosPage);
                    /*this.getFiscalizacoesByAgencia(this.agencia.id)
                    this.getAgenciaContatos(this.agencia.id)*/
                });
            },
            getAgenciasByCidade(id, page){
                this.loading = true;
                this.$apollo.query({
                    query: gql`query ($filter: SearchFilterInput!, $page: Int, $offset: Int){
                        searchAgenciasComTotalFiscalizacoes(filter: $filter, page: $page, offset: $offset){
                            totalCount
                            agencias:itens{
                                id
                                nome
                                codigo
                                ddd
                                telefone
                                centralNf
                                fechadaEm
                                totalFiscalizacoes
                                totalFiscalizacoesPendentes
                            }
                        }
                    }`,
                    variables: {
                        filter: {itens:[{name: 'cidade', cidadeData: {id: id}}]},
                        page: page-1,
                        offset: 10
                    },
                }).then((result) => {
                    this.agenciasTotalCount = result.data.searchAgenciasComTotalFiscalizacoes.totalCount;
                    this.agencias = result.data.searchAgenciasComTotalFiscalizacoes.agencias;
                    this.loading = false;
                });
            },
            getFiscalizacoesByCidade(id, page){
                this.loading = true;
                this.$apollo.query({
                    query: gql`query ($id: Int!, $page: Int, $offset: Int){
                        fiscalizacoesPorCidade2(id: $id, page: $page, offset: $offset){
                            totalCount
                            fiscalizacoes:itens{
                                id
                                numeroSolicitacao
                                dataLimite
                                status
                                regular
                                dataFiscalizacao
                                tipo{
                                    nome
                                }
                                financiamento{
                                    operacao
                                    operacaoDigito
                                    mutuario
                                }
                                dataCancelamento
                            }
                        }
                    }`,
                    variables: {
                        id: id,
                        page: page-1,
                        offset: 10
                    },
                }).then((result) => {
                    this.fiscalizacoesTotalCount = result.data.fiscalizacoesPorCidade2.totalCount;
                    this.fiscalizacoes = result.data.fiscalizacoesPorCidade2.fiscalizacoes;
                    this.loading = false;
                });
            },
            getProjetosByCidade(id, page){
                this.loading = true;
                this.$apollo.query({
                    query: gql`query($id: Int!, $page: Int, $offset: Int) {
                        projetosPorCidade(id: $id, page: $page, offset: $offset){
                            totalCount
                            projetos:itens{
                                id
                                cliente
                                dataSolicitacao
                                dataEnvio
                                tipo{
                                    nome
                                }
                            }
                        }
                    }`,
                    variables: {
                        id: id,
                        page: page -1,
                        offset: 10
                    },
                }).then((result) => {
                    this.projetos = result.data.projetosPorCidade.projetos;
                    this.projetosTotalCount = result.data.projetosPorCidade.totalCount;
                    this.loading = false;
                    //this.$set(this.agencia, 'fiscalizacoes', result.data.agencia.fiscalizacoes)
                });
            },
            montaEndereco(agencia){
                let endereco = agencia.endereco.replace(/\s/g, '+')
                if(agencia.numero){
                    endereco += ',' + agencia.numero.replace(/\s/g, '+');
                }
                if(agencia.bairro){
                    endereco += ',' + agencia.bairro.replace(/\s/g, '+');
                }
                endereco += ',' + agencia.cidade.nome.replace(/\s/g, '+');
                endereco += ',' + agencia.cidade.estado.sigla.replace(/\s/g, '+');
                return endereco
            },
            getMapImageUrl(endereco){
                let url = 'https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyDVmydqrR66sMvCjiFRy9yTXo3KMzJuQIw'
                url += '&zoom=11&size=420x250&maptype=roadmap'
                //url += '&center='+endereco
                url += '&markers=size:mid%7C'+endereco
                //url += '&center=Rua+das+pereias,515,sinop,mt'
                return url;
            },
        }
    }
</script>

<style scoped>
    .label{
        color: #616161 !important;
        caret-color: #616161 !important;

        font-size: 0.75rem !important;
        font-weight: 400;
        letter-spacing: 0.0333333333em !important;
        line-height: 1.25rem;

        display: block !important;
    }

    .v-list-item-finalizado{
        background-color: #f9f9f9;
    }
    .v-list-item-finalizado .v-list-item__title,
    .v-list-item-finalizado .v-list-item__subtitle,
    .v-list-item-finalizado .v-list-item__action-text{
        opacity: 0.7;
    }
    .v-list-item-finalizado::after{
        width: 6px;
        content: "";
        display: inline-block;
        height: 72px; /*92 para 3 linhas*/
        margin-right: -16px;
        margin-left: 16px;
    }
    .finalizado-regular::after{
        background-color: #4CAF50;
    }
    .finalizado-irregular::after{
        background-color: #f31b1b;
    }
</style>